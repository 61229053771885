// const Input = Formio.Components.components.input;
const FieldComponent = Formio.Components.components.field;

class GPAFieldComponent extends FieldComponent {
    /**
     * This is the default schema of your custom component. It will "derive"
     * from the base class "schema" and extend it with its default JSON schema
     * properties. The most important are "type" which will be your component
     * type when defining new components.
     *
     * @param extend - This allows classes deriving from this component to 
     *                 override the schema of the overridden class.
     */
    static schema(...extend) {
        return FieldComponent.schema({
            type: 'gpafield',
            label: 'GPA Field',
            key: 'gpafield'
        });
    }
    
    /**
     * This is the Form Builder information on how this component should show
     * up within the form builder. The "title" is the label that will be given
     * to the button to drag-and-drop on the buidler. The "icon" is the font awesome
     * icon that will show next to it, the "group" is the component group where
     * this component will show up, and the weight is the position within that
     * group where it will be shown. The "schema" field is used as the default
     * JSON schema of the component when it is dragged onto the form.
     */
    static get builderInfo() {
        return {
          title: 'GPA Field',
          icon: 'terminal',
          group: 'basic',
          documentation: '/userguide/#textfield',
          weight: 0,
          schema: GPAFieldComponent.schema()
        };
    }
    
    /**
     * Called when the component has been instantiated. This is useful to define
     * default instance variable values.
     *
     * @param component - The JSON representation of the component created.
     * @param options - The global options for the renderer
     * @param data - The contextual data object (model) used for this component.
     */
    constructor(component, options, data) {
        super(component, options, data);
        this.id = `GPAField-${Math.random().toString(36).slice(2)}`;
        this.gpa_type_id = `GPAType-${Math.random().toString(36).slice(2)}`;
        this.gpa_value_id = `GPAValue-${Math.random().toString(36).slice(2)}`;
        this.inputFiled = `<input type="text" class="input-with-feedback form-control bold" maxlength="140" placeholder="" autocomplete="off">`;
        
        this.selectFieldOpenTag = `<select type="text" autocomplete="off" class="input-with-feedback form-control ellipsis" maxlength="140" placeholder="">`;
        this.selectFieldCloseTag = `</select>`;
        this.selectFieldEmptyOption = `<option></option>`;

        this.selectField = `${this.selectFieldOpenTag} ${this.selectFieldEmptyOption} ${this.selectFieldCloseTag}`;

        this.type = ''
        this.gpa_value = ''

        const protocol = window.location.protocol;
        const hostname = window.location.hostname;
        const port = window.location.port;
        this.currentURL = `${protocol}//${hostname}:${port}/`;
    }
    
    /**
     * Called immediately after the component has been instantiated to initialize
     * the component.
     */
    async init() {
        super.init();
        await fetch(`${this.currentURL}api/method/medad_sis_core.api.get_resource?doctype=GPA Type`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => response.json())
        .then(data => {
            const records = data['data'].map(record => record['name']);

            this.selectField = `
                ${this.selectFieldOpenTag}
                ${this.selectFieldEmptyOption}
                ${records.map(name => `<option value="${name}">${name}</option>`).join('')}
                ${this.selectFieldCloseTag}
            `;

            const gpaTypeElement = document.querySelector(`#${this.gpa_type_id}`);

            if (gpaTypeElement) {
                gpaTypeElement.innerHTML = this.selectField;
            } else {
                console.error(`Element with id ${this.gpa_type_id} not found.`);
            }

        })
        .catch(error => {
            console.error('Error:', error);
        });
    }
    
    /**
     * For Input based components, this returns the <input> attributes that should
     * be added to the input elements of the component. This is useful if you wish
     * to alter the "name" and "class" attributes on the <input> elements created
     * within this component.
     *
     * @return - A JSON object that is the attribute information to be added to the
     *           input element of a component.
     */
    get inputInfo() {
        const info = super.inputInfo;
        return info;
    }
    
    /**
     * This method is used to render a component as an HTML string. This method uses
     * the template system (see Form Templates documentation) to take a template
     * and then render this as an HTML string.
     *
     * @param content - Important for nested components that receive the "contents"
     *                  of their children as an HTML string that should be injected
     *                  in the {{ content }} token of the template.
     *
     * @return - An HTML string of this component.
     */
    render(content) {
        return super.render(`<br><label for="${this.gpa_type_id}" class="col-form-label">${this.t('GPA Type')}</label><div ref="gpafield" id="${this.gpa_type_id}">${this.renderField(1)}</div><br><label for="${this.gpa_value_id}" class="col-form-label">${this.t('GPA Value')}</label><div ref="gpavalue" id="${this.gpa_value_id}">${this.renderField(0)}</div>`)
    }
    
    /**
     * The attach method is called after "render" which takes the rendered contents
     * from the render method (which are by this point already added to the DOM), and
     * then "attach" this component logic to that html. This is where you would load
     * any references within your templates (which use the "ref" attribute) to assign
     * them to the "this.refs" component variable (see comment below).
     *
     * @param - The parent DOM HtmlElement that contains the component template.
     *
     * @return - A Promise that will resolve when the component has completed the
     *           attach phase.
     */
    attach(element) {
        /**
         * This method will look for an element that has the 'ref="customRef"' as an
         * attribute (like <div ref="customRef"></div>) and then assign that DOM
         * element to the variable "this.refs". After this method is executed, the
         * following will point to the DOM element of that reference.
         *
         * this.refs.customRef
         *
         * For DOM elements that have multiple in the component, you would make this
         * say 'customRef: "multiple"' which would then turn "this.refs.customRef" into
         * an array of DOM elements.
         */
        this.loadRefs(element, {
            gpafield: 'single',
            gpavalue: 'single',
        });
        
        /**
         * It is common to attach events to your "references" within your template.
         * This can be done with the "addEventListener" method and send the template
         * reference to that object.
         */
        this.addEventListener(this.refs.gpafield, 'change', () => {
            var type = document.querySelector('#' + this.id + ' select').options[document.querySelector('#' + this.id + ' select').selectedIndex].text;
            var gpa = document.querySelector('#' + this.gpa_value_id);
            if (type) {
                fetch(`${this.currentURL}api/method/medad_sis_adm.apis.application.get_gpa_type?name=${type}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then(response => response.json())
                .then(data => {
                    if (data['message']['type'] === "Number") {
                        gpa.innerHTML = this.inputFiled;
                    } else {
                        const namesArray = data['message']['table'].map(item => item.gpa_type_name);
                        gpa.innerHTML = `
                            ${this.selectFieldOpenTag}
                            ${this.selectFieldEmptyOption}
                            ${namesArray.map(name => `<option value="${name}">${name}</option>`).join('')}
                            ${this.selectFieldCloseTag}
                        `;
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
            } else {
                gpa.innerHTML = this.inputFiled;
            }
        });

        this.addEventListener(this.refs.gpafield, 'input', (e) => {
            this.type = document.querySelector('#' + this.id + ' select').options[document.querySelector('#' + this.id + ' select').selectedIndex].text;
            this.gpa_value = '';
            this.setValue({"gpa_type": this.type, "gpa": this.gpa_value});
        });

        this.addEventListener(this.refs.gpavalue, 'input', (e) => {
            var gpaSelect = document.querySelector('#' + this.gpa_value_id + ' select');
            var gpaField = document.querySelector('#' + this.gpa_value_id + ' input[type="text"]');
            

            if (gpaSelect !== null && gpa_field_value !== '') {
                var gpa_field_value = gpaSelect.options[gpaSelect.selectedIndex].text;
                this.gpa_value = gpa_field_value;
            } else {
                this.gpa_value = gpaField.value;
            }
            this.setValue({"gpa_type": this.type, "gpa": this.gpa_value});

        });

        return super.attach(element);
    }
    
    /**
     * Called when the component has been detached. This is where you would destroy
     * any other instance variables to free up memory. Any event registered with
     * "addEventListener" will automatically be detached so no need to remove them
     * here. 
     *
     * @return - A Promise that resolves when this component is done detaching.
     */
    detach() {
        return super.detach();
    }
 
    /**
     * Called when the component has been completely "destroyed" or removed form the
     * renderer.
     *
     * @return - A Promise that resolves when this component is done being destroyed.
     */
    destroy() {
        return super.destroy();
    }
 
    /**
     * A very useful method that will take the values being passed into this component
     * and convert them into the "standard" or normalized value. For exmample, this
     * could be used to convert a string into a boolean, or even a Date type.
     *
     * @param value - The value that is being passed into the "setValueAt" method to normalize.
     * @param flags - Change propogation flags that are being used to control behavior of the
     *                change proogation logic.
     *
     * @return - The "normalized" value of this component.
     */
    normalizeValue(value, flags = {}) {
        return super.normalizeValue(value, flags);
    }
    
    /**
     * Returns the value of the "view" data for this component.
     *
     * @return - The value for this whole component.
     */
    getValue() {
        return super.getValue();
    }
    
    /**
     * Much like "getValue", but this handles retrieving the value of a single index
     * when the "multiple" flag is used within the component (which allows them to add
     * multiple values). This turns a single value into an array of values, and this
     * method provides access to a certain index value.
     *
     * @param index - The index within the array of values (from the multiple flag) 
     *                that is getting fetched.
     *
     * @return - The view data of this index.
     */
    getValueAt(index) {
        return super.getValueAt(index);
    }
    
    /**
     * Sets the value of both the data and view of the component (such as setting the
     * <input> value to the correct value of the data. This is most commonly used
     * externally to set the value and also see that value show up in the view of the
     * component. If you wish to only set the data of the component, like when you are
     * responding to an HMTL input event, then updateValue should be used instead since
     * it only sets the data value of the component and not the view. 
     *
     * @param value - The value that is being set for this component's data and view.
     * @param flags - Change propogation flags that are being used to control behavior of the
     *                change proogation logic.
     *
     * @return - Boolean indicating if the setValue changed the value or not.
     */
    setValue(value, flags = {}) {
        return super.setValue(value, flags);
    }
    
    /**
     * Sets the value for only this index of the component. This is useful when you have
     * the "multiple" flag set for this component and only wish to tell this component
     * how the value should be set on a per-row basis.
     *
     * @param index - The index within the value array that is being set.
     * @param value - The value at this index that is being set.
     * @param flags - Change propogation flags that are being used to control behavior of the
     *                change proogation logic.
     *
     * @return - Boolean indiciating if the setValue at this index was changed.
     */
    setValueAt(index, value, flags = {}) {
        return super.setValueAt(index, value, flags);
    }
    
    renderField(type){
        return type === 1 ? this.selectField : this.inputFiled
    }

}

Formio.use({
    components: {
        gpafield: GPAFieldComponent
    }
});